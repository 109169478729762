import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const AboutPage = () => (
    <Layout>
      <h1>About Grassfed</h1>
      <p>Grassfed Network is an initiative deployed by <a href="https://coinfund.io">CoinFund</a>, a cryptoasset-focused investment 
      and research firm founded in 2015 and based in Brooklyn, New York. Grassfed Network makes use of <Link to="/mining">generalized mining</Link> strategies 
      to directly engage decentralized networks.  If you would like more information, contact us at <a href="mailto:admin@grassfed.network">admin@grassfed.network</a> or use our <Link to="/contact">contact form</Link>.</p>
    </Layout>
)

export default AboutPage
